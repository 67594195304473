<template>
    <div>
        <div class="comment-section vld-parent">
            <loading 
                :active="retrievingComments" 
                :can-cancel="false" 
                :is-full-page="false"
            />
            <div
                v-for="comment in comments"
                :class="[{'right': comment.is_author}, 'comment']"
                :key="comment.id"
            >
                <img
                    :src="getUserImage(comment)"
                    class="image"
                    alt="User image"
                >
                <div class="comment-content">
                    <div class="header">
                        <template v-if="comment.created_by && comment.created_by.first_name">
                            <b>{{ comment.created_by.first_name }} {{ comment.created_by.last_name }}</b>,
                        </template>
                        {{ comment.created_at }}
                    </div>
                    <div class="body">{{ comment.comment }}</div>
                </div>
            </div>
        </div>

        <div :class="[{
            'm-t-30': comments.length > 0,
        },'add-comment-section']">
            <textarea
                v-model="commentInput"
                class="form-control"
                placeholder="Dodaj interni komentar"
                rows="2"
            ></textarea>
            <button
                class="btn btn-success"
                @click="addComment"
                :disabled="!commentInput || sendingComment"
            >
                <i v-if="sendingComment" class="fa fa-spinner fa-spin"></i>
                <i v-else class="fa fa-paper-plane"></i>
            </button>
        </div>
    </div>
</template>

<script>
import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

export default {
    name: "OrderInternalCommentSection",
    props: {
        orderId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            comments: [],
            commentInput: '',
            sendingComment: false,
            retrievingComments: false,
        }
    },
    methods: {
        addComment() {
            this.sendingComment = true;
            ApiService.post(`/streamline/api/order/${this.orderId}/order-internal-comment/`, {
                comment: this.commentInput,
            })
            .then(() => {
                this.commentInput = '';
                this.retrieveComments();
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri dodajanju komentarja",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.sendingComment = false;
            })
        },
        retrieveComments() {
            this.retrievingComments = true;
            ApiService.get(`/streamline/api/order/${this.orderId}/order-internal-comment/`)
            .then(response => {
                this.comments = response.data;
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri pridobivanju komentarjev",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.retrievingComments = false;
            })
        },
        getUserImage({ created_by, is_author }) {
            const color = is_author ? "337ab7" : "666666";

            function getFirstLetter(name) {
                return name.charAt(0).toUpperCase();
            }
            return `https://eu.ui-avatars.com/api/?background=${color}&color=fff&name=${getFirstLetter(created_by.first_name)}+${getFirstLetter(created_by.last_name)}`
        },
    },
    mounted() {
        this.retrieveComments();
    },
}
</script>

<style scoped>
.add-comment-section {
    display: flex;
    gap: 10px;
}
.add-comment-section button {
    max-height: 42px;
    max-width: 42px;
}

.comment-section {
    display: grid;
    gap: 20px;
}
.comment-section .comment {
    width: min(90%, 600px);
    display: flex;
    gap: 10px;
}
.comment-section .comment.right {
    margin-left: auto;
    flex-direction: row-reverse;
}
.comment-section .comment .image {
    max-height: 42px;
    max-width: 42px;
    border-radius: 7px;
}
.comment-section .comment .comment-content {
    background-color: #e5f7ff;
    padding: 7px 13px;
    width: 100%;
    border-radius: 15px;
    border-top-left-radius: 0;
}
.comment-section .comment.right .comment-content {
    border-top-right-radius: 0;
    border-top-left-radius: 15px;
}
.comment-section .comment .comment-content .body {
    white-space: pre-wrap;
}
</style>
