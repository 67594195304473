<template>
    <div>
        <input 
            v-show="false"
            type="file"
            ref="fileInput"
            @change="handleFileSelected"
            :multiple="multiple"
        />
        <div
            @click="$refs.fileInput.click()"
            @drop="dragAndDrop"
            @dragover="e => e.preventDefault()"
            :class="{'dropzone fade text-center': allowDragAndDrop}"
        >
            <div v-if="defaultStyling">
                <svg width="30" height="33" viewBox="0 0 50 43">
                    <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
                </svg>
                <br />
                <slot name="dragDroptext">
                    <b>{{ $t("documents:choose_document") }}</b> {{ $t("documents:or_drag_and_drop") }}
                </slot>
            </div>
            <slot v-else></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        allowDragAndDrop: {
            type: Boolean,
            default: false,
        },
        defaultStyling: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleFileSelected(){
            this.$emit("file-selected", this.$refs.fileInput.files);
        },
        dragAndDrop(event) {
            if (!this.allowDragAndDrop)
                return;
            event.preventDefault();
            event.stopPropagation();
            this.$refs.fileInput.files = event.dataTransfer.files;
            this.handleFileSelected();
        },
    }
}
</script>