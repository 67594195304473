<template>
    <div class="container-fluid vld-parent">
        <loading 
            :active="loading"
            :can-cancel="false" 
            :is-full-page="false"
        />

        <div class="row">
            <FormulateForm
                v-model="formValues"
            >
                <div class="col-sm-4">
                    <FormPanelSection
                        title="Podatki o poročilu"
                    >
                        <FormulateInput
                            name="appraisal_date"
                            :label="$t('depository:appraisal_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIcon="ti-calendar"
                        />
                        <FormulateInput
                            name="on_site_date"
                            :label="$t('depository:on_site_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIcon="ti-calendar"
                        />
                        <FormulateInput
                            name="report_date"
                            :label="$t('depository:report_date')"
                            type="date"
                            :disabled="isReadOnly"
                            prependIcon="ti-calendar"
                        />
                    </FormPanelSection>

                    <FormPanelSection
                        title="AMAS Analiza"
                        v-if="amasAnalysisEnabled"
                    >
                        <div style="display: flex; flex-direction: column; gap: 10px;">
                            <template v-if="formValues && formValues.analysis_session_appraiser">
                                <a
                                    class="btn btn-default"
                                    style="color: #333 !important;"
                                    :href="`/streamline/supplier/open-amas/${formValues.analysis_session_appraiser}`"
                                >
                                    Odpri v AMAS
                                </a>
                                <button class="btn btn-default" @click="retrieveFromAMAS" :disabled="isReadOnly">Prenesi podatke iz AMAS</button>
                            </template>
                            <button v-else class="btn btn-default" @click="appraiseInAMAS">Oceni v AMAS</button>
                        </div>
                    </FormPanelSection>

                    <FormPanelSection
                        title="Lastniki"
                    >
                        <table class="table">
                            <FormulateInput
                                type="group"
                                name="orderitempropertyowner_set"
                                add-label="+ Dodaj lastnika"
                                :repeatable="true&&!isReadOnly" 
                            >
                                <tr class="align-tr">
                                    <td>
                                        <FormulateInput name="owner_title" type="text" :label="$t('depository:owner_title')"  :disabled="isReadOnly"/>
                                    </td>
                                    <td>
                                        <FormulateInput name="share" :label="$t('depository:share')" placeholder="1/2"  :disabled="isReadOnly" />
                                    </td> 
                                </tr>
                                <tr class="align-tr">
                                    <td>
                                        <FormulateInput name="owner_phone" type="text" :label="$t('depository:owner_phone')"  :disabled="isReadOnly"/>
                                    </td>
                                    <td>
                                        <FormulateInput name="owner_email" type="text" :label="$t('depository:owner_email')"  :disabled="isReadOnly"/>
                                    </td>
                                </tr>
                                <tr class="display-block">
                                    <td class="display-block">
                                        <FormulateInput class="w-100" name="owner_address" type="text" :label="$t('depository:owner_address')"  :disabled="isReadOnly"/>
                                    </td>
                                </tr>
                            </FormulateInput>
                        </table>
                    </FormPanelSection>
                </div>

                <div class="col-sm-8">

                    <FormPanelSection
                        title="Predmet cenitve"
                    >
                        <OrderFormRealEstateTable
                            v-if="formValues.orderitemproperty_set"
                            :properties="formValues.orderitemproperty_set"
                            :isReadOnly="isReadOnly"
                        />
                    </FormPanelSection>

                    <FormPanelSection
                        title="Opisni podatki"
                    >
                        <div class="row">
                            <div class="col-sm-2">
                                <FormulateInput  name="post_office_id" :label="$t('depository:post_office_id')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-2">
                                <FormulateInput  name="post_office_name" :label="$t('depository:post_office_name')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput  name="address" :label="$t('general:address')" type="text" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput  name="country" :label="$t('depository:country')" type="text" :disabled="isReadOnly" />
                            </div>

                            <div class="col-sm-12">
                                <FormulateInput name="short_description" :label="$t('depository:short_description')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="construction_description" :label="$t('depository:construction_description')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="access_description" :label="$t('depository:access_description')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-4">
                                <FormulateInput name="total_size" :label="$t('depository:total_size')" type="number"  :disabled="isReadOnly" />
                            </div>
                            <!-- <div class="col-sm-4">
                                <FormulateInput name="total_size_of_improvements" :label="$t('depository:total_size_of_improvements')" type="number"  :disabled="isReadOnly" />
                            </div> -->
                            <div class="col-sm-4">
                                <FormulateInput name="usable_size" :label="$t('depository:usable_size')" type="text"  :disabled="isReadOnly" />
                            </div>
                        </div>
                    </FormPanelSection>
                </div>
            </FormulateForm>
        </div>
        
        <div class="row">
            <FormulateForm
                v-model="formValues"
            >
                <div class="col-sm-12">
                    <FormPanelSection
                        title="Ocena vrednosti"
                    >
                        <div class="row">
                            <div class="col-sm-12">
                                <FormulateInput name="msov_type" :label="$t('depository:msov_type')" type="select" :options="selectOptions.msov_type"  :disabled="isReadOnly" />
                            </div>
                                <!-- <div class="col-sm-12" >
                                <FormulateInput name="time_to_sell" :label="$t('depository:time_to_sell')" type="number"  :disabled="isReadOnly" />
                            </div> -->
                            <div class="col-sm-12">
                                <FormulateInput name="occupant_type" :label="$t('depository:occupant_type')" type="select" :options="selectOptions.occupant_type"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="real_estate_right" :label="$t('depository:real_estate_right')" type="select" :options="selectOptions.real_estate_right"   :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="market_value_total" :label="$t('depository:market_value_total')" type="text" validation="required" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="liquidation_value" :label="$t('depository:liquidation_value')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="market_value_constuction_completed" :label="$t('depository:market_value_constuction_completed')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="is_building_complete" :label="$t('depository:is_building_complete')" type="select" :options="[
                                    { value: '', label: ''},
                                    { value: '1', label: 'Da' },
                                    { value: '0', label: 'Ne' },
                                ]" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6" >
                                <FormulateInput name="appraisal_type_market_rate_approach" :label="$t('depository:appraisal_type_market_rate_approach')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6" >
                                <FormulateInput  name="is_adaptation" :label="$t('depository:is_adaptation')" type="select"  :options="[
                                    { value: '', label: ''},
                                    { value: '1', label: 'Da' },
                                    { value: '0', label: 'Ne' },
                                ]" :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="appraisal_type_income_based_approach" :label="$t('depository:appraisal_type_income_based_approach')"  type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-6">
                                <FormulateInput name="capitilisation_value" :label="$t('depository:capitilisation_value')"  type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="appraisal_type_purchase_value_approach" :label="$t('depository:appraisal_type_purchase_value_approach')" type="text"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="justification_for_appraisal" :label="$t('depository:justification_for_appraisal')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                            <div class="col-sm-12">
                                <FormulateInput name="notes" :label="$t('depository:notes')" type="textarea"  :disabled="isReadOnly" />
                            </div>
                        </div>
                    </FormPanelSection>
                </div>
            </FormulateForm>
        </div>

        <div class="row">
            <div class="col-sm-12">

                <FormPanelSection title="Dokumenti">
                    <file-upload
                        v-if="!isReadOnly"
                        class="file-upload"
                        :allowDragAndDrop="true"
                        :defaultStyling="true"
                        @file-selected="handleAttachmentUpload"
                        :multiple="true"
                    />

                    <div class="attachments-section">
                        <div
                            v-for="file in uploadingFiles"
                            :key="file.name + (file.id || 'uploading')"
                            class="attachment-item vld-parent"
                        >
                          <loading 
                                :active="file.status === 'deleting'"
                                :can-cancel="false" 
                                :is-full-page="false"
                                :height="30"
                            />
                            <div class="attachment-info">
                                <div class="attachment-main">
                                    <i :class="getAttachmentTypeIcon(file.status)"></i>
    
                                    <div class="attachment-details">
                                        <template v-if="file.editing">
                                            <select 
                                                v-model="file.attachment_type"
                                                class="form-control form-control-sm attachment-type-select"
                                                @change="updateAttachmentType(file)"
                                            >
                                                <option v-for="type in attachmentTypes" :key="type.value" :value="type.value">
                                                    {{ type.label }}
                                                </option>
                                            </select>
                                        </template>
                                        <template v-else>
                                            <span class="attachment-type">{{ getAttachmentTypeName(file.attachment_type) }}</span>
                                            <a
                                                :href="file.file"
                                                target="_blank"
                                                class="attachment-filename"
                                                :class="{ 'uploading': file.status === 'uploading' }"
                                            >
                                                {{ file.name }}
                                            </a>
                                        </template>
                                    </div>
                                </div>

                                <div
                                    v-if="file.status === 'success' && !isReadOnly"
                                    class="attachment-actions"
                                >
                                    <button 
                                        :class="[{
                                            'text-primary': file.editing
                                        }, 'btn btn-link']"
                                        @click="toggleEditMode(file)"
                                    >
                                        <i class="fa" :class="file.editing ? 'fa-check' : 'fa-pencil'"></i>
                                    </button>
                                    <button 
                                        class="btn btn-link text-danger" 
                                        @click="deleteAttachment(file)"
                                        title="Izbriši datoteko">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </FormPanelSection>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Komentarji"
                    v-if="isReadOnly || (formValues.orderitemcorrectionrequest_set && formValues.orderitemcorrectionrequest_set.length && formValues.orderitemcorrectionrequest_set.length > 0)"
                >
                    <div
                        v-if="formValues.orderitemcorrectionrequest_set && formValues.orderitemcorrectionrequest_set.length"
                        class="comment-section"
                    >
                        <div
                            v-for="correctionRequest in formValues.orderitemcorrectionrequest_set"
                            :key="correctionRequest.id"
                            class="comment"
                        >
                            <!-- Icon -->
                            <span v-if="correctionRequest.is_resolved" class="comment-icon resolved fa fa-check" v-tooltip="'Komentar razrešen'"></span>
                            <span v-else class="comment-icon fa fa-exclamation" v-tooltip="'Komentar še ni razrešen'"></span>

                            <div class="comment-content">
                                <div class="comment-header">
                                    <span
                                        v-if="correctionRequest.created_by"
                                        class="created-by-section"
                                    >
                                        {{ correctionRequest.created_by.first_name }} {{ correctionRequest.created_by.last_name }},
                                    </span>
                                    {{ correctionRequest.created_at }}
                                </div>

                                <div class="comment-body">{{ correctionRequest.comment }}</div>

                                <div class="comment-actions" v-if="(!correctionRequest.is_resolved && !isReadOnly) || correctionRequest.resolved_comment">

                                    <template v-if="!correctionRequest.is_resolved && !isReadOnly">
                                        <textarea
                                            v-model="correctionRequest.resolved_comment"
                                            class="form-control"
                                            placeholder="Razreši s komentarjem"
                                        ></textarea>
                                        <div>
                                            <button
                                                class="btn btn-sm btn-success"
                                                @click="resolveCorrectionRequest(correctionRequest)"
                                            >
                                                Razreši
                                                <i class="fa fa-check"></i>
                                            </button>
                                        </div>
                                    </template>

                                    <div v-else>
                                        <div class="comment-header">
                                            <span
                                                v-if="correctionRequest.resolved_by"
                                                class="created-by-section"
                                            >
                                                {{ correctionRequest.resolved_by.first_name }} {{ correctionRequest.resolved_by.last_name }},
                                            </span>
                                            {{ correctionRequest.resolved_at }}
                                        </div>
                                        <div class="comment-body">{{ correctionRequest.resolved_comment }}</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <template v-if="isReadOnly && !isAcceptedAndClosed">
                        <div :class="[{'m-t-30': formValues.orderitemcorrectionrequest_set && formValues.orderitemcorrectionrequest_set.length > 0 }, 'm-b-10']">
                            <textarea
                                v-model="correctionRequestComment"
                                class="form-control"
                                placeholder="Dodaj komentar"
                                rows="3"
                            ></textarea>
                        </div>

                        <div class="text-center">
                            <button class="btn btn-sm btn-info" @click="addCorrectionRequest">
                                Dodaj komentar
                            </button>
                        </div>
                    </template>

                </FormPanelSection>
            </div>
        </div>

        <div class="row" v-if="!isReadOnly">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Akcije"
                >
                    <div class="text-center">
                        <button class="btn btn-info btn-sm" @click="saveDraftOrderItem">
                            Shrani osnutek
                        </button>

                        <button class="btn btn-success btn-sm m-l-10" @click="saveAndSubmitOrderItem">
                            Shrani in oddaj cenitev
                        </button>
                    </div>
                </FormPanelSection>
            </div>
        </div>

        <div class="row" v-if="actionsEnabled && !isAcceptedAndClosed">
            <div class="col-sm-12">
                <FormPanelSection
                    title="Akcije"
                >
                    <div class="text-center">
                        <button class="btn btn-sm btn-warning" @click="requestUpdate">
                            Zavrni cenitev
                        </button>
                        <button class="btn btn-sm btn-success m-l-10" @click="acceptOrder">
                            Sprejmi cenitev
                        </button>
                    </div>
                </FormPanelSection>
            </div>
        </div>

    </div>
</template>
<script>
import Vue from "vue";

import ApiService from "@/components/utils/api.service.js"
import { Swal } from "@/components/utils/swal.alerts.js";

import optionsmixin from "./mixins/optionsmixin.js";

import FileUpload from "@/components/utils/FileUpload";
import OrderFormRealEstateTable from "./order_form_parts/OrderFormRealEstateTable.vue";
import FormPanelSection from "./FormPanelSection.vue";

export default {
    components: {
        OrderFormRealEstateTable,
        FormPanelSection,
        FileUpload,
    },
    mixins: [ optionsmixin ],
    props : {
        orderItemId: {
            type: [String, Number],
            required: false
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        actionsEnabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        amasAnalysisEnabled: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed:{
        isEditMode: function() {
            return this.orderItemId != undefined;
        },
        isReadOnly: function(){
            return this.readOnly || (
                this.formValues &&
                this.formValues.task &&
                this.formValues.task.task_status &&
                ["Submitted", "Accepted and closed"].includes(this.formValues.task.task_status.name));
        },
        isAcceptedAndClosed: function(){
            return this.formValues &&
                this.formValues.task &&
                this.formValues.task.task_status &&
                ["Accepted and closed"].includes(this.formValues.task.task_status.name);
        },
    },
    methods: {
        getOrderItem: function(){
            this.loading = true;
            return ApiService
                .get(`/streamline/api/order-item/${this.orderItemId}/`)
                .then(response => {
                    this.formValues = this._convertBooleanSelect(response.data);
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri pridobivanju cenitve",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        submitOrderItemAction: function( actionName, postData, successMessage, errorMessage ){
            this.loading = true;
            return ApiService
                .post(`/streamline/api/order-item/${this.formValues.id}/${actionName}/`, postData)
                .then(response => {
                        Swal({
                            title: "",
                            text: successMessage,
                            icon: "success",
                        });
                        this.formValues = response.data;
                        this.loading = false;
                        this.$emit("order-item-action-change");
                        return response.data;
                    })
                .catch(error => {
                    Swal({
                        title: errorMessage,
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        checkCommentsAreResolved: async function(swalText, swalButtonText) {
            // if there are any unresolved comments display a confirmation dialog
            if (
                this.formValues.orderitemcorrectionrequest_set &&
                this.formValues.orderitemcorrectionrequest_set.length &&
                this.formValues.orderitemcorrectionrequest_set.some(comment => !comment.is_resolved)
            ) {
                const result = await Swal({
                    title: "Obstajajo nerazrešeni komentarji",
                    text: swalText,
                    icon: "warning",
                    confirmButtonText: swalButtonText,
                    cancelButtonText: "Prekliči",
                    showCancelButton: true,
                })
                if (!result.isConfirmed) {
                    return false;
                }
            }
            return true;
        },
        saveAndSubmitOrderItem: async function() {
            if (await this.checkCommentsAreResolved("Ali ste prepričani, da želite cenitev vseeno oddati?", "Oddaj cenitev")) {
                this
                    .updateOrderItem(this.formValues)
                    .then(_orderItem => {
                        this.submitOrderItemAction("submit", {}, "Cenitev oddana", "Napaka pri oddaji cenitve");
                    });
            }
        },
        saveDraftOrderItem: function() {
            this
                .updateOrderItem(this.formValues)
                .then(_orderItem => {
                    Swal({
                        title: "",
                        text: "Osnutek shranjen",
                        icon: "success",
                    });
                    this.$emit("order-item-saved");
                });
        },
        _convertBooleanSelect: function(data) {
            const booleanSelectMap = {
                null: "",
                [true]: "1",
                [false]: "0",
            };
            data["is_building_complete"] = booleanSelectMap[data["is_building_complete"]];
            data["is_adaptation"] = booleanSelectMap[data["is_adaptation"]];
            return data;
        },
        updateOrderItem: function() {
            this.loading = true;
            return ApiService
                .put(`/streamline/api/order-item/${this.formValues.id}/`, this.formValues)
                .then((response) => {
                    this.formValues = this._convertBooleanSelect(response.data);
                    return this.formValues;
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri posodabljanju cenitve",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        acceptOrder: async function(data) {
            if (await this.checkCommentsAreResolved("Ali ste prepričani, da želite cenitev vseeno sprejeti", "Sprejmi cenitev")) {
                this.submitOrderItemAction("accept", {}, "Cenitev sprejeta", "Napaka pri sprejemanju cenitve");
            }
        },
        requestUpdate: function(data) {
            this.submitOrderItemAction("reject", {"notes": this.formValues.notes_correction}, "Cenitev poslana v dopolnitev", "Napaka pri pošiljanju cenitve v dopolnitev");
        },
        appraiseInAMAS: function() {
            this.loading = true;
            return ApiService
                .post(`/streamline/api/order-item/${this.formValues.id}/analysis`, {
                    re_keys: this.formValues.orderitemproperty_set.map(property => property.re_key),
                })
                .then(res => {
                    if (res.data) {
                        this.formValues.analysis_session_appraiser = res.data.analysis_session_appraiser;
                        Swal({
                            title: "",
                            text: "Analiza uspešno ustvarjena. Do nje lahko dostopate s klikom na gumb 'Odpri v AMAS'.",
                            icon: "success",
                        });
                    } else {
                        Swal({
                            title: "",
                            text: "Napaka pri ustvarjanju analize",
                            icon: "error",
                        });
                    }
                })
                .catch(error => {
                    Swal({
                        title: "Napaka pri ustvarjanju analize v AMAS",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        retrieveFromAMAS: function() {
            this.loading = true;
            ApiService.get(`/streamline/api/order-item/${this.formValues.id}/analysis`)
            .then(res => {
                if (res.data) {
                    this.formValues = {...this.formValues, ...res.data};

                    Swal({
                        title: "",
                        text: "Podatki uspešno pridobljeni",
                        icon: "success",
                    });
                }
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri pridobivanju podatkov iz AMAS",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        handleAttachmentUpload: function(files) {
            if (!files || files.length === 0) return;

            // upload each file
            files.forEach(file => {
                const formData = new FormData();
                formData.append("file", file);

                // add to uploading files list
                const uploadingFile = {
                    name: file.name,
                    status: "uploading",
                    attachment_type: 6,  // Ostalo
                };
                this.uploadingFiles.push(uploadingFile);
                const fileIndex = this.uploadingFiles.indexOf(uploadingFile);

                // Add attachment type to form data
                formData.append("attachment_type", uploadingFile.attachment_type.toString());                

                // Upload the file
                ApiService.post(`/streamline/api/order-item/${this.formValues.id}/attachment/`, formData)
                    .then(response => {
                        // Update the file entry with the response data
                        this.$set(this.uploadingFiles, fileIndex, {
                            ...this.uploadingFiles[fileIndex],
                            ...response.data,
                            status: "success",
                        });

                        // Update the formValues to include the new attachment
                        if (!this.formValues.orderitemattachment_set) {
                            this.formValues.orderitemattachment_set = [];
                        }
                        this.formValues.orderitemattachment_set.push(response.data);
                    })
                    .catch(() => {
                        this.uploadingFiles[fileIndex].status = "error";
                        Swal({
                            title: "Napaka pri nalaganju prilog",
                            text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                            icon: "error",
                        })
                    });
            });
        },
        deleteAttachment: function(file) {
            if (!file.id) return;

            Vue.set(file, "status", "deleting");
            ApiService.delete(`/streamline/api/order-item/${this.formValues.id}/attachment/${file.id}/`)
                .then(() => {
                    // Remove from uploadingFiles
                    const index = this.uploadingFiles.findIndex(f => f.id === file.id);
                    if (index !== -1) {
                        this.uploadingFiles.splice(index, 1);
                    }

                    // Remove from orderitemattachment_set
                    const attachmentIndex = this.formValues.orderitemattachment_set.findIndex(f => f.id === file.id);
                    if (attachmentIndex !== -1) {
                        this.formValues.orderitemattachment_set.splice(attachmentIndex, 1);
                    }
                })
                .catch(() => {
                    Swal({
                        title: "Napaka pri brisanju prilog",
                        text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                        icon: "error",
                    })
                });
        },
        getAttachmentTypeName: function(type) {
            const attachmentType = this.attachmentTypes.find(t => t.value === parseInt(type));
            return attachmentType ? attachmentType.label : "Neznani tip";
        },
        getAttachmentTypeIcon: function(status) {
            if (status === "uploading") {
                return "fa fa-spinner fa-spin";
            } else if (status === "error") {
                return "fa fa-exclamation-triangle text-danger";
            }
            return "ti ti-file"
        },
        getFilenameFromUrl: function(url) {
            // Extract filename from URL, removing any query parameters
            const urlPath = url.split("?")[0];
            const urlParts = urlPath.split("/");
            return decodeURIComponent(urlParts[urlParts.length - 1]);
        },
        initializeUploadingFiles: function() {
            if (this.formValues.orderitemattachment_set) {
                this.uploadingFiles = this.formValues.orderitemattachment_set.map(attachment => ({
                    name: this.getFilenameFromUrl(attachment.file),
                    status: "success",
                    attachment_type: attachment.attachment_type,
                    file: attachment.file,
                    id: attachment.pk
                }));
            }
        },
        toggleEditMode: function(file) {
            // If we're canceling edit mode, revert the type back to original
            if (file.editing) {
                file.attachment_type = file.original_type;
            } else {
                file.original_type = file.attachment_type;
            }
            this.$set(file, 'editing', !file.editing);
        },

        updateAttachmentType: function(file) {
            if (!file.id) return;

            const formData = new FormData();
            formData.append("attachment_type", file.attachment_type.toString());

            ApiService.put(`/streamline/api/order-item/${this.formValues.id}/attachment/${file.id}/`, formData)
                .then(response => {
                    // Update both arrays with new data
                    const updatedFile = {
                        ...file,
                        ...response.data,
                        name: this.getFilenameFromUrl(response.data.file),
                        status: "success",
                        editing: false
                    };

                    // Update uploadingFiles
                    const index = this.uploadingFiles.findIndex(f => f.id === file.id);
                    if (index !== -1) {
                        this.$set(this.uploadingFiles, index, updatedFile);
                    }

                    // Update orderitemattachment_set
                    const attachmentIndex = this.formValues.orderitemattachment_set.findIndex(f => f.id === file.id);
                    if (attachmentIndex !== -1) {
                        this.$set(this.formValues.orderitemattachment_set, attachmentIndex, response.data);
                    }
                })
                .catch(() => {
                    // Revert the type back on error
                    file.attachment_type = file.original_type;
                    file.editing = false;
                });
        },
        // Comments
        addCorrectionRequest: function() {
            if (!this.correctionRequestComment) {
                Swal({
                    title: "Komentar je obvezen",
                    text: "Prosimo, vnesite komentar, ki bo viden cenilcu",
                    icon: "warning",
                })
                return;
            }

            this.loading = true;
            ApiService.post(`/streamline/api/order-item/${this.formValues.id}/correction-request/`, {
                comment: this.correctionRequestComment,
            })
            .then(res => {
                this.formValues = res.data;
                this.correctionRequestComment = "";
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri dodajanju komentarja",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        resolveCorrectionRequest: function(comment) {
            this.loading = true;
            ApiService.post(`/streamline/api/order-item-correction-request/${comment.id}/`, {
                resolved_comment: comment.resolved_comment,
            })
            .then(res => {
                Object.assign(comment, res.data);
            })
            .catch(error => {
                Swal({
                    title: "Napaka pri razreševanju komentarja",
                    text: "Prosimo, poskusite ponovno ali nas kontaktirajte na podpora@arvio.si",
                    icon: "error",
                })
                throw error;
            })
            .finally(() => {
                this.loading = false;
            })
        },
    },
    data() {
        return {
            formValues: {},
            correctionRequestComment: "",
            loading: false,
            uploadingFiles: [],
            attachmentTypes: [
                { value: 1, label: "Poročilo cenitve" },
                { value: 2, label: "Gradbeno dovoljenje" },
                { value: 3, label: "Uporabno dovoljenje" },
                { value: 4, label: "Tloris objekta" },
                { value: 5, label: "ZK izpisek" },
                { value: 6, label: "Ostalo" }
            ],
        }
    },
    mounted() {
        this.getOrderItem()
            .then(() => {
                this.initializeUploadingFiles();
            });
    }

}
</script>

<style scoped>
.form-section{
    background-color: #dedede;
    padding:20px 10px;
    margin:15px 0;
}
.checkbox-property {
    height: 45px;
}
.align-tr{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

}
.file-upload {
    margin-bottom: 12px;
}
.attachments-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}
.attachment-item {
    padding: 8px 12px;
    background: #f8f9fa;
    border: 1px solid #eee;
    border-radius: 4px;
    transition: background-color 0.2s;
}
.attachment-item:hover {
    background: #e9ecef;
}
.attachment-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.attachment-link {
    display: flex;
    align-items: center;
    color: #007bff;
    text-decoration: none;
    gap: 10px;
    flex: 1;
}
.attachment-details {
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.attachment-type {
    font-weight: 500;
    color: #333;
}
.attachment-filename {
    color: #007bff;
    text-decoration: none;
    font-size: 0.9em;
    word-break: break-all;
}
.attachment-filename:hover {
    text-decoration: underline;
    color: #0056b3;
}
.attachment-filename.uploading {
    pointer-events: none;
    opacity: 0.7;
}
.attachment-main {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
}
.attachment-main i {
    margin-right: 4px;
}
.attachment-actions {
    display: flex;
    align-items: center;
    gap: 8px;
}

.attachment-actions .btn-link {
    padding: 4px 8px;
    color: #dc3545;
}

.attachment-actions .btn-link:hover {
    color: #bd2130;
}

.attachment-type-select {
    width: auto;
    min-width: 200px;
    display: inline-block;
}

.attachment-actions .btn-link {
    padding: 4px 8px;
}

.attachment-actions .text-danger:hover {
    color: #bd2130;
}

.attachment-actions .text-primary:hover {
    color: #0056b3;
}

.comment-section {
    display: grid;
    gap: 20px;
}
.comment {
    display: flex;
    gap: 10px;

    border: 1px solid #dee2e6;
    padding: 15px 15px 15px 10px;
    border-radius: 7px;
}
.comment-content {
    flex: 1;
}
.comment-header {
    margin-bottom: 5px;
}
.comment-header .created-by-section {
    font-weight: bold;
}
.comment-icon {
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;

    background-color: #f0ad4e;
    color: white;
    border-radius: 100%;
}
.comment-icon.resolved {
    background-color: #5cb85c;
}
.comment-body {
    white-space: pre-wrap;
}
.comment-actions {
    margin-top: 10px;
    border-top: 1px dashed #dee2e6;
    padding-top: 10px;

    display: flex;
    gap: 5px;
}
.comment-actions button {
    height: 100%;
}
</style>
